import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import PubSub from 'pubsub-js';
import PropTypes from 'prop-types';
import moment from 'moment-mini';
import Account from '../../managers/Account';
import SimpleModal from '../modal/modal';
import Slider from '../slider';
import LoginForm from '../account/loginForm';
import LoginWidget from './loginWidget';
import { getParameterByName } from '../../managers/Helpers';
// import MetamaskWallet from '../wallet/metamaskWallet';
// import AccountDropdown from '../common/AccountDropdown';
// import Dropdown from '../common/Dropdown';

class TopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      username: '',
      showModal: false,
      showMobileNav: false,
      isCountdown: false,
      currentDate: moment().unix() * 1000,
      isFrench: false,
      isHome: true,
    };
    PubSub.subscribe('account_updated', this.onPubSubEvent);
  }

  onPubSubEvent = () => {
    this.componentDidMount();
  }

  logout = () => {
    Account.logout();
  }

  getAccountDetails = async () => {
    const accountData = await Account.get(Account.getToken());
    if (accountData) {
      this.setState({
        username: accountData.username,
      });
    }
  }

  async componentDidMount() {
    const { dropDetails } = this.props;
    const { currentDate } = this.state;
    let countdownDate;
    if (window.location.pathname !== '/') {
      this.setState({
        isHome: false,
      });
    }
    // Check if dropData.drop has a countdown set. If it doesn, always display the countdown page
    if (process.env.E_DROP_ONLY !== 'true') {
      if (dropDetails.drop.countDown && dropDetails.drop.countDown.countDownDate) {
        countdownDate = moment(dropDetails.drop.countDown.countDownDate).unix() * 1000;
      }
      if (countdownDate > currentDate) {
        this.setState({
          isCountdown: true,
        });
      }
    }
    if (Account.isLoggedIn()) {
      await this.getAccountDetails();
      this.setState({
        isLoggedIn: true,
      });
    } else {
      this.setState({
        isLoggedIn: false,
      });
    }

    const topnav = document.getElementById('topNav');
    if (window.location.href.includes('account')
    || window.location.href.includes('/login')
    || window.location.href.includes('/register')) {
      topnav.classList.add('-animate');
    } else {
      document.addEventListener('scroll', () => {
        if (window.scrollY > 40) {
          topnav.classList.add('-animate');
        } else {
          topnav.classList.remove('-animate');
        }
      });
    }
  }

  loginSuccess = async () => {
    const successUrl = window.location.href + '#login=success';
    this.setState({
      showModal: false,
      isLoggedIn: true,
    });
    // console.log(window.location.href);
    PubSub.publish('account_updated');
    // if (window.location.pathname.includes('register')) {
    //   window.location.pathname = '/drop/' + process.env.DROP_ID;
    // }
    if (process.env.E_DROP_ONLY === 'true') {
      window.location.href = '/account/collection';
    } else {
      window.location.href = successUrl;
      window.location.reload();
    }
  }

  loginFailed = (error) => {
    toast.error(error || 'Oops! Something went wrong. Try again later.');
  }

  openLoginModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      showModal: true,
      showMobileNav: false,
    });
  }

  toggleNav = (e) => {
    e.preventDefault();
    const { showMobileNav } = this.state;
    this.setState({
      showMobileNav: !showMobileNav,
    });
  }

  handleMobileNav = () => {
    this.setState({
      showMobileNav: false,
    });
  }

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  }

  render() {
    const { navType } = this.props;
    const {
      isCountdown, isLoggedIn, showModal, showMobileNav, username, isFrench, isHome,
    } = this.state;
    return (
      <div id="topNav" className="top-nav">
        <Slider isOpen={showModal} onBeforeClose={() => this.setState({ showModal: false })}>
          {showModal
          && (
          <LoginForm
            onSuccess={() => this.loginSuccess()}
            onFail={(error) => this.loginFailed(error)}
            onRegisterButton={() => this.closeModal()} />
          )}
        </Slider>
        {/* <SimpleModal isOpen={showModal} onBeforeClose={() => this.setState({ showModal: false })}>
          <LoginForm
            onSuccess={() => this.loginSuccess()}
            onFail={(error) => this.loginFailed(error)}
            onRegisterButton={() => this.closeModal()} />
        </SimpleModal> */}

        {/* <div className="top-nav__vertical">

        </div> */}

        <div className="top-nav__inner">
          {isCountdown
            ? (
              <Link to="/countdown">
                <img className="top-nav__logo -vertical" src={process.env.NAV_LOGO} alt={process.env.SITE_NAME} />
              </Link>
            )
            : (
              <Link to="/">
                <img className="top-nav__logo -vertical" src={process.env.NAV_LOGO} alt={process.env.SITE_NAME} />
              </Link>
            )}
          {Account.isLoggedIn()
            ? (
              <LoginWidget
                isLoggedIn={isLoggedIn}
                onLoginButton={() => this.openLoginModal()}
                onLogoutButton={() => this.logout()} />
            )
            : (
              <div>
                <div className={!isHome ? 'login-widget__cta' : 'login-widget__cta -home'}>
                  <a
                  id="loginButton"
                  href="#"
                  className="button-nav"
                  onClick={(e) => this.openLoginModal(e)}>
                    Log In
                  </a>
                </div>
                {/* {isHome
                && (
                <div className="login-widget__language">
                  <a href="/?l=en" className={!isFrench ? '-selected' : ''}>EN</a>
                  {' '}
                  |
                  {' '}
                  <a href="?l=fr" className={isFrench ? '-selected' : ''}>FR</a>
                </div>
                )} */}
              </div>
            )}

          {/* {isLoggedIn
            ? (
              <div></div>
            )
            : (
              <div className={showMobileNav ? 'top-nav__ctas -show-mobile' : 'top-nav__ctas'}>
                <a
                  href="#"
                  className="button-nav"
                  onClick={(e) => this.openLoginModal(e)}>
                  Log In
                </a>
                <Link
                  className="button-nav"
                  to="/register"
                  onClick={(e) => this.handleMobileNav(e)}>
                  Register
                </Link>
              </div>
            )} */}
        </div>
      </div>
    );
  }
}

TopNav.propTypes = {
  dropDetails: PropTypes.objectOf(PropTypes.object()),
  navType: PropTypes.string,
};

export default TopNav;
