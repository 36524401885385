import { set } from 'lodash';
import React, { useState, useEffect } from 'react';

const BristolStudioTiers = ({ lang, expandTiers }) => {
  const [showTiers, setShowTiers] = useState(true);
  const [hideExpander, setHideExpander] = useState(false);

  const copyStrings = {
    en: {
      heading: 'WHAT\'S MY MEMBER\'S SUITE TIER?',
      tableHeading: 'Benefits',
      tableColHeader1: 'Rookie',
      tableColDesc1: '0-500 Points',
      tableColHeader2: 'All Star',
      tableColDesc2: '501-1500 Points',
      tableColHeader3: 'MVP',
      tableColDesc3: '1501 points+',
      tableRowDesc1: 'Welcome Reward',
      tableRowDesc2: 'Free Shipping',
      tableRowDesc4: 'Birthday Reward',
      tableRowDesc5: 'Periodic Early Access',
      tableRowDesc6: 'New Drops',
      tableRowDesc7: 'Exclusive Collaborations',
      tableRowDesc8: 'Invitations to Events',
      tableRowDesc10: 'Free Mystery Items',
    },
  };

  const toggleTiers = () => {
    setShowTiers(!showTiers);
  };

  useEffect(() => {
    // on mount
    if (expandTiers) {
      setShowTiers(true);
      setHideExpander(true);
    }
  }, []);

  return (
    <div className={!hideExpander ? 'custom-tiers__breakdown' : 'custom-tiers__breakdown -slim'}>
      <div className={!hideExpander ? 'custom-tiers__inner' : 'custom-tiers__inner -slim'}>
        {!hideExpander
        && (
        <div className="custom-tiers__heading" onClick={(e) => toggleTiers()}>
          {copyStrings[lang].heading}
          <a><img className={showTiers ? '-rotate' : ''} src="https://assets.heyethos.com/bonlook/landing/icon-down-arrow.png" alt="What’s my Trendsetter tier?" /></a>
        </div>
        )}
        {showTiers
        && (
        <div className={!hideExpander ? 'custom-tiers__table' : 'custom-tiers__table -slim'}>
          <table>
            <tr>
              <th>{copyStrings[lang].tableHeading}</th>
              <th>
                {copyStrings[lang].tableColHeader1}
                <span>{copyStrings[lang].tableColDesc1}</span>
              </th>
              <th>
                {copyStrings[lang].tableColHeader2}
                <span>{copyStrings[lang].tableColDesc2}</span>
              </th>
              <th>
                {copyStrings[lang].tableColHeader3}
                <span>{copyStrings[lang].tableColDesc3}</span>
              </th>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc1}</td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/bristolstudio/icon-star.png" alt="Star" /></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/bristolstudio/icon-star.png" alt="Star" /></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/bristolstudio/icon-star.png" alt="Star" /></td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc2}</td>
              <td></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/bristolstudio/icon-star.png" alt="Star" /></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/bristolstudio/icon-star.png" alt="Star" /></td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc4}</td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/bristolstudio/icon-star.png" alt="Star" /></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/bristolstudio/icon-star.png" alt="Star" /></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/bristolstudio/icon-star.png" alt="Star" /></td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc5}</td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/bristolstudio/icon-star.png" alt="Star" /></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/bristolstudio/icon-star.png" alt="Star" /></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/bristolstudio/icon-star.png" alt="Star" /></td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc6}</td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/bristolstudio/icon-star.png" alt="Star" /></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/bristolstudio/icon-star.png" alt="Star" /></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/bristolstudio/icon-star.png" alt="Star" /></td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc7}</td>
              <td></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/bristolstudio/icon-star.png" alt="Star" /></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/bristolstudio/icon-star.png" alt="Star" /></td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc8}</td>
              <td></td>
              <td></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/bristolstudio/icon-star.png" alt="Star" /></td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc10}</td>
              <td></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/bristolstudio/icon-star.png" alt="Star" /></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/bristolstudio/icon-star.png" alt="Star" /></td>
            </tr>
          </table>
        </div>
        )}
      </div>
    </div>
  );
};
export default BristolStudioTiers;
