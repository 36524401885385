/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

function BristolStudioFAQ({ noHeader, lang }) {
  const [questions, setQuestions] = useState([]);
  const toggleAnswer = (id, e) => {
    e.preventDefault();
    const cloneQuestions = [...questions];
    cloneQuestions.forEach((faq) => {
      if (faq.id === id) {
        faq.isOpen = !faq.isOpen;
      }
    });
    setQuestions(cloneQuestions);
  };

  const faq = {
    en: [
      {
        id: 1,
        title: 'How do I join?',
        answer: 'Your email will contain a login link. Click through to access your membership.',
      },
      {
        id: 2,
        title: 'Where can I track progress?',
        answer: 'You can track your progress by logging into your Member’s Suite rewards program',
      },
      {
        id: 3,
        title: 'How is my membership status determined?',
        answer: 'Your Member’s Suite tier is determined by how many points you earn throughout the year. There are lots of ways to earn. Not just by spending!',
      },
      {
        id: 4,
        title: 'How do I earn?',
        answer: 'Earn 1 point for every $1 spent on a purchase.',
      },
      {
        id: 5,
        title: 'Do tiers/points expire or reset?',
        answer: 'No, points do not expire or reset.',
      },
      {
        id: 6,
        title: 'Leveling up to the Next Tier: How it works.',
        answer: 'You\'ll graduate to the next level once you\'ve reached the max amount of points in your current one. There are so many ways to earn points, not just spending. Sharing on social or with your friends can also earn you points!',
      },
      {
        id: 7,
        title: 'What happens to my rewards if I return something?',
        answer: 'When you return an item, that amount will be deducted from your yearly spending total.',
      },
    ],
  };

  useEffect(() => {
    setQuestions(faq.en);
  }, []);

  return (
    <div className="asset-faq">
      {!noHeader
      && <h2 className="digital-asset__subheader -center">FAQ</h2>}
      {questions.map((faq) => (
        <div
          key={faq.id}
          className="asset-faq__item">
          {faq.isOpen
            ? (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                -
              </a>
            )
            : (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                +
              </a>
            )}
          <div
            className="asset-faq__item--question"
            onClick={(e) => toggleAnswer(faq.id, e)}>
            {faq.title}
          </div>
          <div
            className={faq.isOpen ? 'asset-faq__item--answer -open' : 'asset-faq__item--answer'}
            dangerouslySetInnerHTML={{ __html: faq.answer }}>
          </div>
        </div>
      ))}
      
    </div>
  );
}

export default BristolStudioFAQ;
