import React, { useState, useEffect } from 'react';

const ClassicSixHowItWorks = ({ lang }) => {
  // const [transferCode, setTransferCode] = useState(null);
  const copyStrings = {
    en: {
      heading: 'HOW DOES THIS WORK?',
    },
  };

  const instructions = {
    en: [
      {
        id: 1,
        title: 'Join',
        description: 'By entering your email, you’re a member! We’re happy you signed up.',
      },
      {
        id: 2,
        title: 'Check your email',
        description: 'Your email will contain your login link. Click through to explore your membership.',
      },
      {
        id: 3,
        title: 'Start earning',
        description: 'Join our community and enjoy the benefits of being a member. Our benefits include behind-the-scenes videos, exclusive discounts, and event invites.',
      },
    ],
  };

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div className="how-it-works__wrapper">
      <div id="startContent" className="how-it-works">
        <div className="how-it-works__title">
          {copyStrings[lang].heading}
        </div>
        <div className="how-it-works__list">
          {instructions[lang].map((step, index) => (
            <div key={step.id} className="how-it-works__item">
              <div className="how-it-works__number">{index + 1}</div>
              <div className="how-it-works__step-title">{step.title}</div>
              <div className="how-it-works__step-description">{step.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default ClassicSixHowItWorks;
